/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable dot-notation */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from "gatsby";
import 'jquery';


import CMSWrapper from '@ic-hosting/ich-contentful-cms-wrapper';

import { updateData } from '../data/contentful/actions';
import Layout from '../components/layout';

import Logo from '../components/logoSVG'
import ProductWidget from '../components/product/productWidget';
import FullPageLander from '../components/IndexPage/FullPageLander';
import SectionImageRight from '../components/IndexPage/SectionImageRight';
import SectionImageLeft from '../components/IndexPage/SectionImageLeft';
import SectionFitness from '../components/IndexPage/SectionFitness';
import SectionAboutClub from '../components/IndexPage/SectionAboutClub';
import SectionInstructors from '../components/IndexPage/SectionInstructors';
import SectionGallery from '../components/IndexPage/SectionGallery';
import SectionNews from '../components/IndexPage/SectionNews';

const layoutName = "1JiktKjiPZyxSZGAyDUWS8";

class ProductTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.state,
    };
  }

  componentWillMount() {

  }

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.updateData();
  }

  render() {
	try {
		const {
		  // data,
		  pageContext,
		  location,
		  content,
		} = this.props;
		const { slug } = pageContext;
		const { layouts } = content.data;
		const productData = content.data.annexProduct[slug];
		const products = content.data.annexProduct['all'];
		return (
		<Layout runAllMain>
			<section className="page_breadcrumbs cs section_padding_50 columns_padding_5">
				<div className="container">
					<div className="row"> {/* <span className="section_bg_header">{productData.title}</span> */ }
						<div className="col-xs-12 col-sm-10 col-sm-offset-1 text-center">
							{/* <h1 className="sr-only">{productData.title}</h1> */ }
							<Link to="/" className="logo"> <img src={layouts[layoutName].logo.size({
							width: 350,
							format: 'png'
							})} /> </Link>
							<ol className="breadcrumb">
								<li> <Link to="/">
							Home
						</Link> </li>
								<li className="active"> <span>{productData.title}</span> </li>
							</ol>
						</div>
					</div>
   				</div>
			</section>
			<section className="gs">
				<div className="container">
					<div className="row">
						<div className="col-xs-12">
						{productData.ratioImage ? <div>
							<img src="//images.ctfassets.net/3wza6zjg8unz/2ed9b57QfematBurWT7Dzi/e7270a2aeb3916f232101c53d3a75a26/Artboard_28_2x.png?fm=png" />
							<img className="floatRight" src={productData.ratioImage.size({width: 300, format: 'png'})} />
						</div> : null}
						</div>
					</div>
				</div>
			</section>
			<section className="ds section_padding_top_150 section_padding_bottom_150 columns_padding_30">
				<div className="container">
					<div className="row">
						<div className="col-xs-12">
							<div itemScope="" itemType="http://schema.org/Product" className="product type-product row columns_padding_30 columns_margin_bottom_30">
								<div className="col-sm-6">
									<div className="images text-center"> <a href={productData.featureImage.size({ width: 400 })} itemProp="image" className="woocommerce-main-image zoom prettyPhoto" data-gal="prettyPhoto[product-gallery]">
					            		<img src={productData.featureImage.size({ width: 400 })} className="attachment-shop_single wp-post-image" alt="" title="" />
					        		</a> </div>
								<div className="thumbnails-wrap">
									<div id="product-thumbnails" className="owl-carousel thumbnails product-thumbnails" data-margin="10" data-nav="false" data-dots="true" data-responsive-lg="4" data-responsive-md="4" data-responsive-sm="3" data-responsive-xs="2">
										{productData.images.map((im, i) => (
											<a key={`images-${i}`} href={im.size({width:400})} className="zoom first" title="" data-gal="prettyPhoto[product-gallery]">
												<img src={im.size({width:400})} className="attachment-shop_thumbnail" alt="" />
											</a>
										))}
					                </div>
								</div>
								</div>
								<div className="summary entry-summary col-sm-6">
										<h1 itemProp="name" className="product_title">{productData.title}</h1>
										<span className="small-text highlight">{productData.subTitle}</span>
                                		<span className="entry-title text-uppercase">{productData.categoryTitle}</span>
										<span className="price">
										<span className="amount">RRP {productData.price}</span> </span>
									<div className="short-description">
										<p>{productData.shortDescription}</p>
									</div>
									<div className="product_meta highlightlinks">
										<ul className="list-unstyled feature-list bottommargin_0">
											<li>
												<div className="row">
													<div className="col-sm-12 list2" dangerouslySetInnerHTML={{__html: productData.packSizes}}></div></div></li>
<li>
												<div className="row">
													<div className="col-sm-3"><span>Application:</span></div>
													<div className="col-sm-9" dangerouslySetInnerHTML={{__html: productData.application}}></div></div></li>
<li>
												<div className="row">
													<div className="col-sm-3"><span>Country of Origin:</span></div>
													<div className="col-sm-9" dangerouslySetInnerHTML={{__html: productData.country}}></div></div></li>
										</ul>
									</div>
								</div>
							</div>
							<div className="woocommerce-tabs">
								<ul className="nav nav-tabs wc-tabs" role="tablist">
									<li className="active"><a href="#details_tab" role="tab" data-toggle="tab">Description</a></li>
									<li><a href="#applications_tab" role="tab" data-toggle="tab">Applications</a></li>
									<li><a href="#technical_tab" role="tab" data-toggle="tab">Technnical Data</a></li>
									<li><a href="#reviews_tab" role="tab" data-toggle="tab">Reviews</a></li>
									<li><a href="#disclaimer_tab" role="tab" data-toggle="tab">Disclaimer</a></li>
								</ul>
								<div className="tab-content big-padding top-color-border">
									<div className="tab-pane fade in active" id="details_tab">
										<div className="row">
											<div className="col-sm-12 list2" dangerouslySetInnerHTML={{__html: productData.description}} />
											{/* <div className="col-sm-3"><span>Standards:</span></div>
											<div className="col-sm-9">MIL-PRF-63460F Type B: Does not comply with pour
point. Full compliance to be offered by S10 variant </div></div>
											<div className="row">
												<div className="col-sm-3"><span>Material Compatibility:</span></div>
												<div className="col-sm-9">
													<ul className="list2 darklinks">
														<li>Compatible with most natural and synthetic seal materials</li>
														<li>Information on compatibility with specific seal materials is available upon request</li>
													</ul>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-3"><span>Workplace Health &amp; Safety:</span></div>
												<div className="col-sm-9">
													<ul className="list2 darklinks">
														<li>Expected to present no specific hazards under normal conditions of expected use</li>
														<li>Refer to SDS before use, avoid personal contact and observe good industrial hygiene</li>
													</ul>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-3"><span>Volatile Organic Compounds:</span></div>
												<div className="col-sm-9">
													<ul className="list2 darklinks">
														<li>Emits a moderate level of Volatile Organic Compounds into the atmosphere</li>
														<li>Features a high flash point and high initial boiling point compared to many alternatives</li>
													</ul>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-3"><span>Marine Toxicity:</span></div>
												<div className="col-sm-9">
													<ul className="list2 darklinks">
														<li>Minor components are harmful to the aquatic environment</li>
														<li>OECD 201, 202, 203: To be evaluated</li>
													</ul>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-3"><span>Biodegradability:</span></div>
												<div className="col-sm-9">
													OECD 301B: To be evaluated – expected to be readily	biodegradable
										</div> */}
										</div> 
									</div>
									<div className="tab-pane fade" id="applications_tab">
										{productData.applictions.length ?
											productData.applictions.map((a, i) => (
											<div key={`applications-${i}`} className="comments-area" id="comments">
												<ol className="commentlist">
													<li className="comment" id="li-comment-22">
														<div className="comment_container">
															<div className="comment-text">
																<div className="meta inline-content with_dividers highlight">{a.author}
																<span className="comment-date highlight">
																	<time dateTime={a.date}>
																		{new Date(a.date).toLocaleDateString()}
																	</time>
																</span> 
																</div>
																<div className="description" dangerouslySetInnerHTML={{__html: a.content}} />
															</div>
														</div>
													</li>
												</ol>
											</div>
											))
										: <div className="comments-area" id="comments">
											<ol className="commentlist">
												<li className="comment" id="li-comment-22">
													Have a great idea or unusual use that you think we should know about. Contact us below and let us know!
												</li>
											</ol>
										</div>
										}
									</div>
									<div className="tab-pane fade" id="reviews_tab">
										{productData.reviews.length ?
											productData.reviews.map((a, i) => (
											<div key={`applications-${i}`} className="comments-area" id="comments">
												<ol className="commentlist">
													<li className="comment" id="li-comment-22">
														<div className="comment_container">
															<div className="comment-text">
																<div className="meta inline-content with_dividers highlight">{a.author}
																<span className="comment-date highlight">
																	<time dateTime={a.date}>
																		{new Date(a.date).toLocaleDateString()}
																	</time>
																</span> 
																</div>
																<div className="description" dangerouslySetInnerHTML={{__html: a.content}} />
															</div>
														</div>
													</li>
												</ol>
											</div>
											))
										: <div className="comments-area" id="comments">
											<ol className="commentlist">
												<li className="comment" id="li-comment-22">
													Enjoyed our produce and want to share your experience, contact us below, we would love to hear your story.
												</li>
											</ol>
										</div>
										}
									</div>
									<div className="tab-pane fade" id="technical_tab" dangerouslySetInnerHTML={{__html: productData.technical}} />
									<div className="tab-pane fade" id="disclaimer_tab" dangerouslySetInnerHTML={{__html: productData.disclaimer}} />
								</div>
							</div>
							<div className="related-products">
								<h2>Related Products</h2>
								<div className="container">
									<div className="row">
										<div className="col-xs-12">
											{/*  */ }
											{products.map((p, i) => (
												<ProductWidget key={`relatedProduct-${i}`} product={content.data.annexProduct[p.id]} />
											))}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
		);
	} catch (e) {
		return <div>{e.message}</div>;
	}
  }
}

const mapStateToProps = state => ({ state, content: state.contentful });

const mapDispatchToProps = dispatch => ({
  updateData: bindActionCreators(updateData, dispatch),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductTemplate);
